<template>
    <div class="page-section-mask">
        <section
            id="start"
            class="billing"
        >
            <!-- results -->
            <div class="wrapper">
                <loading-overlay v-if="loading && !batchesTotal" />

                <no-result
                    v-if="!loading && batchesTotal === 0"
                    type="billing"
                />

                <div
                    v-if="batchesTotal > 0"
                    class="billing__list"
                >
                    <template v-for="(batch, index) in batches">
                        <billing-card
                            :key="batch.batchid"
                            :index="index"
                            :data="batch"
                        />
                    </template>
                </div>

                <div class="wrapper_loadmore">
                    <custom-button
                        v-if="batchesLastPage"
                        :loading="loading"
                        default
                        class="btn-base_colored sort-button btn-page-location"
                        @on-btn-click="goNextPage()"
                    >
                        Load more
                    </custom-button>
                </div>
            </div>
        </section>
    </div>
</template>
<script>

import { createNamespacedHelpers } from 'vuex'
import { eventBus } from '@/helpers/event-bus'
// components
import NoResult from '@/components/common/NoResult'
import Api from '@/helpers/api/index.js'

// icon
import BillingCard from '@/components/account/Cards/BillingCard/BillingCard.vue'

import {
    RESET
} from '@/store/modules/order/mutation-types'

const {
    mapMutations: mapOrderMutations
} = createNamespacedHelpers('order')

export default {
    components: {
        NoResult,
        BillingCard
    },
    metaInfo: {
        title: 'Billing'
    },
    data() {
        return {
            batches: [],
            batchesTotal: null,
            batchesLastPage: null,
            loading: true,
            currentSorting: null,
            totalVisible: 10,
            apiSetting: {
                mode: 'list',
                page: 1,
                per_page: 5,
                id: '',
                sort_order: 'DESC',
                sort_by: 'created_at'
            },
            sortFields: [
                {
                    name: 'Date',
                    value: 'created_at'
                },
                {
                    name: 'Title',
                    value: 'Title'
                }
            ]
        }
    },
    created() {
        this.getData()
    },
    methods: {
        ...mapOrderMutations({
            resetOrder: RESET
        }),
        async goNextPage() {
            if (this.batchesLastPage) {
                this.apiSetting.page += 1
                await this.getData(false)
            }
        },
        async getData(with_total = true) {
            this.loading = true
            try {
                const requestArr = [
                    Api.get('/api/order-batch/list', { ...this.apiSetting })
                ]
                if (with_total) {
                    requestArr.push(Api.get('/api/order-batch/list', { ...this.apiSetting, mode: 'count' }))
                }
                const [list, totals] = await Promise.all(requestArr)
                this.batches = [...this.batches, ...list.data.data]
                if (with_total) {
                    this.batchesTotal = totals?.data.count
                }
                this.batchesLastPage = list.data.next_page_url
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        onActionUpdateFilter(filter) {
            this.apiSetting = { ...this.apiSetting, ...filter }
            this.batches = []
            this.getData()
        }
    }
}
</script>
<style lang="scss">
    .billing {
        .card:last-child {
            margin-bottom: 30px;
        }
    }
</style>
