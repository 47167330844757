<template>
    <div class="no-result">
        <h1
            class="no-result__title no-result__title_question"
            :class="`no-result__title_question-${type}`"
        >
            {{ title ? title : decorData[type].title }}
        </h1>
        <p class="no-result__description">
            {{ description ? description : decorData[type].description }}
        </p>

        <p
            v-if="type===`orders`"
        >
            <router-link
                class="btn-base btn-main"
                :to="{ name: 'order', params: {} }"
            >
                Create an order
            </router-link>
        </p>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String
        },
        title: {
            type: String
        },
        description: {
            type: String
        }
    },
    data() {
        return {
            decorData: {
                tickets: {
                    title: 'No issues',
                    description: 'Create a new support ticket, if you need help.'
                },
                orders: {
                    title: 'No orders found',
                    description: 'You will see data here after you place your first order'
                },
                billing: {
                    title: 'No transactions',
                    description: 'You will see data here after you place your first order'
                },
                'order-details': {
                    title: 'No order data',
                    description: 'No data available for this order.'
                },
                friend: {
                    title: 'No purchases',
                    description: 'Your friend has not made any purchases yet'
                },
                friends: {
                    title: 'No friends',
                    description: 'You will see data here after you register your first friend.'
                },
                writers: {
                    title: 'No writers',
                    description: 'No writers yet'
                },
                reviews: {
                    title: 'No reviews',
                    description: 'No reviews yet'
                },
                'writers-messages': {
                    title: 'No messages',
                    description: 'No messages yet'
                },
                files: {
                    title: 'No files',
                    description: 'No files yet'
                },
                credits: {
                    title: 'No credits',
                    description: 'You can earn credits when your registered friends make an order. Your credits can be cashed out!'
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.no-result {
    padding: 0 20px;
    font-family: $font-family-base;
    margin: 10vh auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__title {
        text-transform: uppercase;
        color: rgba(175, 178, 185, .6);
        font-size: 40px;
        font-weight: 900;
        letter-spacing: 2.4px;
        text-align: center;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        &_question {
            &:before {
                content: '';
                display: block;
                width: 100px !important;
                height: 100px !important;
                background: $no-result__before-bg;
                margin-bottom: 20px;
                background-size: contain;
                background-position: center;
            }
        }
    }

    &__description {
        color: rgba(175, 178, 185, .6);
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        white-space: pre-line;
    }

    &__action {
        margin-top:24px;
        text-align: center;
    }

    .btn-base{
        margin-top: 20px;
    }
}
</style>
